import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { makeStyles } from "@material-ui/styles";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";
import GradingIcon from "@mui/icons-material/Grading";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import Button from "@material-ui/core/Button";
import IconButton from "@mui/material/IconButton";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Hidden from "@mui/material/Hidden";

const useStyles = makeStyles((theme) => ({
  buttonStyle: {
    color: "#0c345a",
    fontFamily: "Big Shoulders Text",
    fontWeight: 600,
    fontSize: 16,
    width: "175px",
    marginLeft: "10px",
    marginTop: "5px",
    marginBottom: "5px",
    marginRight: "10px",
    "&:hover": {
      backgroundColor: "#0c345a",
      color: "#cce3f8",
    },
  },
  customCard: {
    boxShadow: "0 0 15px 0 rgb(0 0 0 / 50%)",
    borderRadius: "4px",
    backgroundColor: "#cce3f8",
    color: "#0c345a",
  },
  imgContainer: {
    textAlign: "right",
    "@media (max-width: 960px)": {
      textAlign: "center",
    },
  },
  descriptContainer: {
    textAlign: "left",
    margin: "auto",
    "@media (max-width: 960px)": {
      textAlign: "center",
      paddingBottom: "10px",
    },
  },
  responsiveImage: {
    height: "250px",
    width: "333px",
    marginTop: "10px",
    marginBottom: "10px",
    "@media (max-width: 960px)": {
      height: "150px",
      width: "200px",
    },
  },
}));

export default function FeaturedContent({ section }) {
  const classes = useStyles();
  const [multipleItems, setMultipleItems] = useState(false);
  const [itemIndex, setItemIndex] = useState(0);
  const [itemType, setItemType] = useState("");

  useEffect(() => {
    if (section.productService) {
      if (section.productService.length > 1) {
        setMultipleItems(true);
        setItemType("product");
      }
    }
    if (section.expertAdvice) {
      if (section.expertAdvice.length > 1) {
        setMultipleItems(true);
        setItemType("advice");
      }
    }
  }, [section.productService, section.expertAdvice]);

  const handleItemDecrement = () => {
    if (itemIndex >= 1) {
      setItemIndex(itemIndex - 1);
    } else if (itemType === "product") {
      setItemIndex(section.productService.length - 1);
    } else if (itemType === "advice") {
      setItemIndex(section.expertAdvice.length - 1);
    }
  };

  const handleItemIncrement = () => {
    if (itemType === "product") {
      if (itemIndex < section.productService.length - 1) {
        setItemIndex(itemIndex + 1);
      } else {
        setItemIndex(0);
      }
    }
    if (itemType === "advice") {
      if (itemIndex < section.expertAdvice.length - 1) {
        setItemIndex(itemIndex + 1);
      } else {
        setItemIndex(0);
      }
    }
  };

  return (
    <>
      {section.productService && (
        <Row className={"g-0"} style={{ padding: "20px" }}>
          <Col xs={12} className={classes.customCard}>
            <div
              style={{
                textAlign: "center",
                fontSize: "26px",
                padding: "10px",
              }}
            >
              {section.title}
            </div>

            <Row
              className={"g-0"}
              style={{
                backgroundColor: "#ffffff",
              }}
            >
              <Col
                lg={1}
                xs={2}
                style={{
                  textAlign: "center",
                  margin: "auto",
                }}
              >
                {multipleItems && (
                  <IconButton
                    aria-label="previous product"
                    onClick={handleItemDecrement}
                  >
                    <ArrowBackIosIcon fontSize="inherit" />
                  </IconButton>
                )}
              </Col>
              <Col lg={5} xs={8} className={classes.imgContainer}>
                <Hidden mdUp>
                  <img
                    src={`${section.productService[itemIndex].mainImage.asset.url}?h=300&w=400&fit=crop&fm=webp`}
                    alt="logo"
                    className={classes.responsiveImage}
                  />
                </Hidden>
                <Hidden mdDown>
                  <img
                    src={`${section.productService[itemIndex].mainImage.asset.url}?h=250&w=333&fit=crop&fm=webp`}
                    alt="logo"
                    className={classes.responsiveImage}
                  />
                </Hidden>
              </Col>
              <Hidden mdUp>
                <Col
                  lg={1}
                  xs={2}
                  style={{
                    textAlign: "center",
                    margin: "auto",
                  }}
                >
                  {multipleItems && (
                    <IconButton
                      aria-label="next product"
                      onClick={handleItemIncrement}
                    >
                      <ArrowForwardIosIcon fontSize="inherit" />
                    </IconButton>
                  )}
                </Col>
              </Hidden>

              <Col lg={5} xs={12} className={classes.descriptContainer}>
                <div style={{ fontSize: "18px" }}>
                  {section.productService[itemIndex].productTitle} -{" "}
                  {section.productService[itemIndex].productSize &&
                    section.productService[itemIndex].productSize}
                  <div>
                    {section.productService[itemIndex].productPrice &&
                      section.productService[itemIndex].productSalePrice ===
                        null && (
                        <span>{`$${section.productService[
                          itemIndex
                        ].productPrice.toFixed(2)}`}</span>
                      )}

                    {section.productService[itemIndex].productPrice &&
                      section.productService[itemIndex].productSalePrice !==
                        null && (
                        <>
                          <span
                            className="text-muted"
                            style={{ textDecoration: "line-through" }}
                          >{`$${section.productService[
                            itemIndex
                          ].productPrice.toFixed(2)}`}</span>
                          {` - `}
                          <span
                            style={{ color: "#cf1b45" }}
                          >{`$${section.productService[
                            itemIndex
                          ].productSalePrice.toFixed(2)}`}</span>
                        </>
                      )}
                  </div>
                </div>
                <div className="text-muted" style={{ fontSize: "16px" }}>
                  <div>by {section.productService[itemIndex].brand}</div>
                  <div>
                    {section.productService[itemIndex].productCategories &&
                      `Product Categories: ${section.productService[itemIndex].productCategories}`}
                  </div>
                  <div>
                    {section.productService[itemIndex].concernCategories &&
                      `Concern Categories: ${section.productService[itemIndex].concernCategories}`}
                  </div>
                </div>
              </Col>
              <Hidden mdDown>
                <Col
                  lg={1}
                  xs={2}
                  style={{
                    textAlign: "center",
                    margin: "auto",
                  }}
                >
                  {multipleItems && (
                    <IconButton
                      aria-label="next product"
                      onClick={handleItemIncrement}
                    >
                      <ArrowForwardIosIcon fontSize="inherit" />
                    </IconButton>
                  )}
                </Col>
              </Hidden>
            </Row>
            <Row className={"g-0"}>
              <Col xs={12} style={{ padding: "15px" }}>
                <div
                  style={{
                    textAlign: "center",
                    fontSize: "12px",
                  }}
                >
                  <div>Expert Rating</div>
                  <Rating
                    name="half-rating-read"
                    precision={0.5}
                    value={section.productService[itemIndex].productRating}
                    readOnly
                    style={{ color: "#0c345a" }}
                    emptyIcon={
                      <StarIcon
                        style={{
                          opacity: 0.25,
                          color: "#0c345a",
                        }}
                        fontSize="inherit"
                      />
                    }
                  />
                </div>
                <div style={{ textAlign: "center" }}>
                  <Link
                    to={`/reviews/${section.productService[itemIndex].slug.current}`}
                    style={{ textDecoration: "none" }}
                  >
                    <Button
                      className={classes.buttonStyle}
                      size="small"
                      variant="outlined"
                      startIcon={<GradingIcon />}
                    >
                      Read Review
                    </Button>
                  </Link>
                  <a
                    href={section.productService[itemIndex].productUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button
                      className={classes.buttonStyle}
                      size="small"
                      variant="outlined"
                      startIcon={<ShoppingCartCheckoutIcon />}
                    >
                      Buy Now
                    </Button>
                  </a>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      {section.expertAdvice && (
        <Row className={"g-0"} style={{ padding: "20px" }}>
          <Col xs={12} className={classes.customCard}>
            <Row
              className={"g-0"}
              style={{
                borderRadius: "4px 4px 0 0",
                textAlign: "center",
                fontSize: "26px",
                padding: "10px",
              }}
            >
              <Col>{section.title}</Col>
            </Row>
            <Row
              className={"g-0"}
              style={{
                backgroundColor: "#ffffff",
              }}
            >
              <Col
                lg={1}
                xs={2}
                style={{
                  textAlign: "center",
                  margin: "auto",
                }}
              >
                {multipleItems && (
                  <IconButton
                    aria-label="previous product"
                    onClick={handleItemDecrement}
                  >
                    <ArrowBackIosIcon fontSize="inherit" />
                  </IconButton>
                )}
              </Col>
              <Col lg={10} xs={8} style={{ textAlign: "center" }}>
                <Hidden mdUp>
                  <img
                    src={`${section.expertAdvice[itemIndex].mainImage.asset.url}?h=150&w=200&fit=crop&fm=webp`}
                    alt="logo"
                    className={classes.responsiveImage}
                  />
                </Hidden>
                <Hidden mdDown>
                  <img
                    src={`${section.expertAdvice[itemIndex].mainImage.asset.url}?h=250&w=333&fit=crop&fm=webp`}
                    alt="logo"
                    className={classes.responsiveImage}
                  />
                </Hidden>
              </Col>
              <Col
                lg={1}
                xs={2}
                style={{
                  textAlign: "center",
                  margin: "auto",
                }}
              >
                {multipleItems && (
                  <IconButton
                    aria-label="next product"
                    onClick={handleItemIncrement}
                  >
                    <ArrowForwardIosIcon fontSize="inherit" />
                  </IconButton>
                )}
              </Col>
            </Row>
            <Row
              className={"g-0"}
              style={{
                backgroundColor: "#ffffff",
                textAlign: "center",
                padding: "10px",
              }}
            >
              <Col xs={12}>
                <p
                  style={{
                    fontSize: "20px",
                    marginBottom: "0px",
                  }}
                >
                  {section.expertAdvice[itemIndex].title}
                </p>
                <div
                  className="text-muted"
                  style={{
                    fontSize: "16px",
                    marginBottom: "15px",
                  }}
                >
                  <div>
                    {section.expertAdvice[itemIndex].author &&
                      `By: ${section.expertAdvice[itemIndex].author}`}
                  </div>
                  <div>
                    {section.expertAdvice[itemIndex].publishedAt &&
                      `Published: ${section.expertAdvice[
                        itemIndex
                      ].publishedAt.slice(0, -14)}`}
                  </div>
                  <div>
                    {section.expertAdvice[itemIndex].productCategories &&
                      `Product Categories: ${section.expertAdvice[itemIndex].productCategories}`}
                  </div>
                  <div>
                    {section.expertAdvice[itemIndex].productCategories &&
                      `Concern Categories: ${section.expertAdvice[itemIndex].concernCategories}`}
                  </div>
                </div>
              </Col>
            </Row>
            <Row className={"g-0"}>
              <Col xs={12} style={{ padding: "15px" }}>
                <div style={{ textAlign: "center" }}>
                  <Link
                    to={`/expert-advice/${section.expertAdvice[itemIndex].slug.current}`}
                    style={{ textDecoration: "none" }}
                  >
                    <Button
                      className={classes.buttonStyle}
                      size="small"
                      variant="outlined"
                      startIcon={<GradingIcon />}
                    >
                      Read Now
                    </Button>
                  </Link>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
}
