import React, { useState, useEffect } from "react";
import client from "../client";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Popper from "@mui/material/Popper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import { makeStyles } from "@material-ui/styles";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  popper: {
    color: "#0c345a",
    textAlign: "center",
    height: "auto",
    maxHeight: "70vh",
    overflowY: "auto",
    width: "30vw",
    pointerEvents: "auto",
    backgroundColor: "#f5faff",
    boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.8)",
    zIndex: "20",
  },
}));

const Search = ({ products }) => {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [advice, setAdvice] = useState([]);
  const openPoppover = Boolean(anchorEl);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "expertAdvice"]{
            title,
            slug,
            "author": author -> name,
            mainImage {
                asset -> {
                  _id,  
                  url
                }
            },
        }`
      )
      .then((data) => setAdvice(data))
      .catch(console.error);
  }, []);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    const allItems = products.concat(advice);

    const searchResults = allItems.filter((item) => {
      if (item.brand) {
        if (
          item.reviewTitle
            .toLowerCase()
            .includes(event.target.value.toLowerCase()) |
          item.productTitle
            .toLowerCase()
            .includes(event.target.value.toLowerCase()) |
          item.brand.toLowerCase().includes(event.target.value.toLowerCase())
        ) {
          return item;
        }

        if (item.productCategories) {
          if (
            item.productCategories
              .toString()
              .toLowerCase()
              .includes(event.target.value.toLowerCase())
          ) {
            return item;
          }
        }

        if (item.concernCategories) {
          if (
            item.concernCategories
              .toString()
              .toLowerCase()
              .includes(event.target.value.toLowerCase())
          ) {
            return item;
          }
        }
      } else {
        if (item.author) {
          if (
            item.author.toLowerCase().includes(event.target.value.toLowerCase())
          ) {
            return item;
          }
        }

        if (item.title) {
          if (
            item.title.toLowerCase().includes(event.target.value.toLowerCase())
          ) {
            return item;
          }
        }
      }

      return null;
    });

    if (event.target.value === "") {
      setSearchResults([]);
      handlePopoverClose();
    } else {
      if (searchResults.length > 0) {
        setSearchResults(searchResults);
      }
      if (searchResults.length === 0) {
        handlePopoverClose();
      } else {
        handlePopoverOpen(event);
      }
    }
  };

  const cancelSearch = () => {
    setSearchTerm("");
    setSearchResults([]);
    handlePopoverClose();
  };

  return (
    <div>
      <FormControl sx={{ width: "100%" }} variant="outlined">
        <InputLabel
          htmlFor="outlined-adornment-search"
          size="small"
          style={{
            fontFamily: "Big Shoulders Text",
            fontWeight: 500,
            fontSize: 16,
          }}
        >
          Search
        </InputLabel>
        <OutlinedInput
          id="outlined-adornment-search"
          type="text"
          size="small"
          onChange={handleSearch}
          value={searchTerm}
          endAdornment={
            <InputAdornment position="end">
              {searchTerm === "" ? (
                <IconButton aria-label="toggle search" edge="end">
                  <SearchIcon />
                </IconButton>
              ) : (
                <IconButton
                  aria-label="toggle search"
                  onClick={cancelSearch}
                  edge="end"
                >
                  <CloseIcon />
                </IconButton>
              )}
            </InputAdornment>
          }
          label="Search"
        />
      </FormControl>
      <Popper
        id={"new popper"}
        className={classes.popper}
        open={openPoppover}
        anchorEl={anchorEl}
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [25, 5],
            },
          },
        ]}
      >
        <div
          style={{
            backgroundColor: "#f5faff",
            height: "100%",
            padding: "10px",
            fontFamily: "Big Shoulders Text",
          }}
        >
          <div>
            <List>
              {searchResults.map((result, key) => (
                <Link
                  to={`${result.author ? "/expert-advice/" : "/reviews/"}${
                    result.slug.current
                  }`}
                  style={{ textDecoration: "none", color: "#0c345a" }}
                  onClick={cancelSearch}
                  key={key}
                >
                  <ListItem disablePadding key={key}>
                    <ListItemButton>
                      <ListItemAvatar>
                        <Avatar
                          alt="Remy Sharp"
                          src={`${result.mainImage.asset.url}?h=50&w=50&bg=ffffff&fit=fillmax&fm=webp`}
                          style={{
                            borderRadius: "0px",
                            height: "50px",
                            width: "50px",
                          }}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <span
                            style={{
                              fontFamily: "Big Shoulders Text",
                              fontWeight: 400,
                              fontSize: 18,
                            }}
                          >
                            {result.author ? (
                              <span>{result.title}</span>
                            ) : (
                              <>
                                <span>{result.productTitle} </span>
                                <span
                                  className="text-muted"
                                  style={{ fontSize: 14 }}
                                >
                                  by {result.brand}
                                </span>
                              </>
                            )}
                          </span>
                        }
                        secondary={
                          <span
                            style={{
                              fontFamily: "Big Shoulders Text",
                              fontWeight: 400,
                              fontSize: 14,
                            }}
                          >
                            {result.title ? (
                              <span
                                className="text-muted"
                                style={{ fontSize: 14 }}
                              >
                                by {result.author}
                              </span>
                            ) : (
                              <>
                                {result.productCategories && (
                                  <span style={{ display: "block" }}>
                                    Product Categories:{" "}
                                    {result.productCategories.map(
                                      (cat, i, arr) => (
                                        <span key={i}>
                                          {cat}
                                          {i !== arr.length - 1 ? ", " : ""}
                                        </span>
                                      )
                                    ) || ""}
                                  </span>
                                )}

                                {result.concernCategories && (
                                  <span style={{ display: "block" }}>
                                    Concern Categories:{" "}
                                    {result.concernCategories.map(
                                      (cat, i, arr) => (
                                        <span key={i}>
                                          {cat}
                                          {i !== arr.length - 1 ? ", " : ""}
                                        </span>
                                      )
                                    ) || ""}
                                  </span>
                                )}
                              </>
                            )}
                          </span>
                        }
                        style={{ marginLeft: "10px" }}
                      />
                    </ListItemButton>
                  </ListItem>
                </Link>
              ))}
            </List>
          </div>
        </div>
      </Popper>
    </div>
  );
};

export default Search;
