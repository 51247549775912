import React, { useState, useEffect, Suspense, lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Row, Col } from "reactstrap";
import Header from "./components/Header";
import Home from "./pages/Home";
import ScrollToTop from "./components/ScrollToTop";
import Loading from "./pages/Loading";
import { makeStyles } from "@material-ui/styles";
import client from "./client";

const Footer = lazy(() => import("./components/Footer"));
const Contact = lazy(() => import("./pages/Contact"));
const SingleReview = lazy(() => import("./pages/SingleReview"));
const About = lazy(() => import("./pages/About"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const TermsOfUse = lazy(() => import("./pages/TermsOfUse"));
const Products = lazy(() => import("./pages/Products"));
const ExpertAdvice = lazy(() => import("./pages/ExpertAdvice"));
const SingleExpertAdvice = lazy(() => import("./pages/SingleExpertAdvice"));

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "Big Shoulders Text",
    height: "100%",
    backgroundColor: "rgba(216, 176, 175, .35)",
  },
}));

function App() {
  const classes = useStyles();
  const [headerFilterBy, setHeaderFilterBy] = useState();
  const [metaContent, setMetaContent] = useState([]);
  useEffect(() => {
    client
      .fetch(
        `*[_type == "metaTags"]{
            pageName,
            pageDescription,
            pageKeywords,
        }`
      )
      .then((data) => setMetaContent(data))
      .catch(console.error);
  }, []);

  return (
    <div className={classes.root}>
      <BrowserRouter>
        <ScrollToTop />
        <Row className={"g-0"}>
          <Col lg={12}>
            <Header setHeaderFilterBy={setHeaderFilterBy} />
          </Col>
        </Row>
        <Row className={"g-0"}>
          <Col lg={1}> </Col>
          <Col lg={10}>
            <Suspense fallback={<Loading />}>
              <Routes>
                <Route
                  path="/"
                  exact
                  element={<Home metaContent={metaContent} />}
                />
                <Route
                  path="/products"
                  element={
                    <Products
                      headerFilterBy={headerFilterBy}
                      setHeaderFilterBy={setHeaderFilterBy}
                      metaContent={metaContent}
                    />
                  }
                />
                <Route path="/reviews/:slug" element={<SingleReview />} />
                <Route
                  path="/expert-advice"
                  element={<ExpertAdvice metaContent={metaContent} />}
                />
                <Route
                  path="/expert-advice/:slug"
                  element={<SingleExpertAdvice />}
                />
                <Route
                  path="/contact"
                  element={<Contact metaContent={metaContent} />}
                />
                <Route
                  path="/about"
                  element={<About metaContent={metaContent} />}
                />
                <Route
                  path="/terms"
                  element={<TermsOfUse metaContent={metaContent} />}
                />
                <Route
                  path="/privacy"
                  element={<PrivacyPolicy metaContent={metaContent} />}
                />
              </Routes>
            </Suspense>
          </Col>
          <Col lg={1}> </Col>
        </Row>
        <Row className={"g-0"}>
          <Col lg={12}>
            <Suspense fallback={<Loading />}>
              <Footer />
            </Suspense>
          </Col>
        </Row>
      </BrowserRouter>
    </div>
  );
}

export default App;
