import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { makeStyles } from "@material-ui/styles";
import brandHero from "../images/brand-hero.webp";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { CardActionArea } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import client from "../client";
import FeaturedContent from "../components/FeaturedContent";
import { Helmet } from "react-helmet";
import TransformBlockContent from "../components/TransformBlockContent";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "10px",
    minHeight: "100%",
    marginTop: "110px",
    width: "100%",
    backgroundColor: "#e9f3fc",
    color: "#8d6544",
    boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.8)",
    padding: "15px",
    display: "flex",
    backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, .2), rgba(245, 250, 255, 1)), url(${brandHero})`,
    backgroundPosition: "5vw 8vh",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    backgroundSize: "100%",
    "@media (max-width: 960px)": {
      textAlign: "center",
      backgroundImage: "none",
    },
  },
  textBanners: {
    fontFamily: "Big Shoulders Text",
    fontSize: "52px",
    textAlign: "center",
    color: "#0c345a",
    "@media (max-width: 960px)": {
      fontSize: "30px",
    },
  },
  buttonStyle: {
    color: "#0c345a",
    fontFamily: "Big Shoulders Text",
    fontWeight: 600,
    fontSize: 16,
    width: "175px",
    marginLeft: "10px",
    marginRight: "10px",
    "&:hover": {
      backgroundColor: "#0c345a",
      color: "#cce3f8",
    },
  },
  customCard: {
    boxShadow: "0 0 15px 0 rgb(0 0 0 / 50%)",
    borderRadius: "4px",
    backgroundColor: "#cce3f8",
    color: "#0c345a",
  },
  customTextCard: {
    boxShadow: "0 0 15px 0 rgb(0 0 0 / 50%)",
    borderRadius: "4px",
    backgroundColor: "#ffffff",
    color: "#0c345a",
    padding: "16px",
  },
}));

export default function Home({ metaContent }) {
  const classes = useStyles();
  const [sections, setSections] = useState([]);
  const [textSections, setTextSections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [metaInfo, setMetaInfo] = useState();

  useEffect(() => {
    if (metaContent) {
      setMetaInfo(metaContent.filter((item) => item.pageName === "Home")[0]);
    }
  }, [metaContent]);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "homePageText"]{
            title,
            body,
        }`
      )
      .then((data) => setTextSections(data))
      .catch(console.error);
  }, []);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "homePage" && active == true] | order(_createdAt asc){
            title,
            "productService": productService[] -> {
              productTitle,
              brand,
              slug,
              productUrl,
              productRating,
              productPrice,
              productSalePrice,
              productSize,
              "productCategories": productCategories[] -> title,
              "concernCategories": concernCategories[] -> title,
              mainImage {
                  asset -> {
                    _id,  
                    url
                  }
              },
              alt
          },
            "expertAdvice": expertAdvice[] -> {
              title,
              slug,
              "author": author -> name,
              publishedAt,
              mainImage {
                  asset -> {
                    _id,  
                    url
                  }
              },
              alt
          },
        }`
      )
      .then((data) => setSections(data))
      .then(() => setLoading(false))
      .catch(console.error);
  }, []);

  return (
    <Row className={"g-0"}>
      <Helmet>
        <title>Home | 7 Step Skin Care</title>
        <meta
          name="description"
          content={metaInfo ? metaInfo.pageDescription : "Default description"}
        />
        <meta
          name="keywords"
          content={metaInfo ? metaInfo.pageKeywords : "Default keywords"}
        />
      </Helmet>

      <Col xs={12} className={classes.root}>
        <Row className={"g-0 w-100"}>
          <Col md={5}></Col>
          <Col md={7} xs={12}>
            <Row>
              <Col xs={12} className={classes.textBanners}>
                <h1>Discover your next beauty breakthrough!</h1>
              </Col>
              {loading
                ? [1, 2, 3].map((elem, index) => (
                    <Col xs={12} style={{ padding: "20px" }} key={index}>
                      <Card>
                        <CardActionArea style={{ margin: "1%", width: "98%" }}>
                          <Skeleton
                            sx={{ height: "360px" }}
                            animation="wave"
                            variant="rectangular"
                          />
                          <CardContent
                            style={{
                              height: "100px",
                              textAlign: "center",
                            }}
                          >
                            <React.Fragment>
                              <Skeleton
                                animation="wave"
                                height={10}
                                style={{ marginBottom: 6 }}
                              />
                              <Skeleton
                                animation="wave"
                                height={10}
                                width="80%"
                              />
                            </React.Fragment>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Col>
                  ))
                : sections.map((section) => (
                    <FeaturedContent key={section.title} section={section} />
                  ))}
            </Row>
            <Row style={{ marginTop: "40px" }}>
              <Col xs={12} className={classes.textBanners}>
                <h1>
                  Helping skin care lovers everywhere discover the latest in
                  aesthetics
                </h1>
              </Col>
            </Row>
            <Row className={"g-0"} style={{ padding: "10px" }}>
              <Col xs={12} className={classes.customTextCard}>
                {textSections.map((section) => (
                  <div
                    key={section.title}
                    style={{
                      fontFamily: "Roboto Condensed",
                      textAlign: "left",
                      padding: "15px",
                    }}
                  >
                    <TransformBlockContent blocks={section.body} />
                  </div>
                ))}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
