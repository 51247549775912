import React from "react";
import { Row, Col } from "reactstrap";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "10px",
    minHeight: "75vh",
    marginTop: "110px",
    height: "auto",
    width: "100%",
    backgroundColor: "#f5faff",
    color: "#0c345a",
    boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.8)",
    padding: "15px",
    textAlign: "left",
    display: "flex",
  },
}));

export default function Loading() {
  const classes = useStyles();
  return (
    <Row className={"g-0"}>
      <Col xs={12} className={classes.root}>
        <Row className={"g-0"} style={{ width: "100%" }}>
          <h1
            style={{
              fontSize: "22px",
              textAlign: "center",
              backgroundColor: "#cce3f8",
              padding: "10px",
              borderRadius: "10px",
              height: "50px",
            }}
          >
            Loading...
          </h1>
          <Col lg={3}></Col>
          <Col lg={6} xs={12} style={{ marginTop: "20px" }}></Col>
          <Col lg={3}></Col>
        </Row>
      </Col>
    </Row>
  );
}
