import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { makeStyles } from "@material-ui/styles";
import brandLogo from "../images/brand-logo.webp";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@material-ui/core/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import MenuIcon from "@mui/icons-material/Menu";
import Hidden from "@mui/material/Hidden";
import Popper from "@mui/material/Popper";
import brandHero from "../images/brand-hero.webp";
import client from "../client";
import Search from "./Search";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  headerRoot: {
    backgroundColor: "#f5faff",
    boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.8)",
    padding: "15px",
    textAlign: "left",
    display: "flex",
    marginBottom: "20px",
    position: "fixed",
    top: 0,
    width: "100%",
    zIndex: "10",
    "@media (max-width: 960px)": {
      textAlign: "center",
      backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, .4), rgba(0, 0, 0, .6)), url(${brandHero})`,
      backgroundPosition: "top left",
      backgroundRepeat: "no-repeat",
      backgroundSize: "100%",
      height: "100px",
      borderRadius: "0 0 10px 10px",
    },
  },
  brandLogo: {
    height: "60px",
    width: "247px",
    "@media (max-width: 960px)": {
      height: "35px",
      width: "156px",
    },
  },
  navButtons: {
    textDecoration: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width: 960px)": {
      paddingTop: "15px",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  smallNavDrawer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  smallNavDrawerButton: {
    "@media (max-width: 960px)": {
      marginTop: "10px",
      marginBottom: "10px",
      backgroundColor: "#d8b0af",
      textDecoration: "none !important",
      textTransform: "none !important",
      color: "#0c345a",
      "&:hover": {
        backgroundColor: "#0c345a",
        color: "#cce3f8",
      },
      fontSize: "12px",
    },
  },
  buttonStyle: {
    fontFamily: "Big Shoulders Text",
    fontWeight: 400,
    fontSize: 22,
    height: "35px",
    textDecoration: "none",
    textTransform: "none",
    color: "#0c345a",
    "&:hover": {
      backgroundColor: "#0c345a",
      color: "#cce3f8",
    },
  },
  listItem: {
    fontSize: 18,
    color: "#0c345a",
    "&:hover": {
      backgroundColor: "#0c345a",
      color: "#cce3f8",
    },
  },
  leftButton: {
    borderRadius: "5px 0 0 5px",
  },
  midButton: {
    borderRadius: "0",
  },
  rightButton: {
    borderRadius: "0 5px 5px 0",
  },
  navBox: {
    width: "auto",
    height: "100%",
    backgroundColor: "#f5faff",
    color: "#0c345a",
  },
  popper: {
    color: "#0c345a",
    textAlign: "center",
    height: "auto",
    maxHeight: "70vh",
    overflowY: "auto",
    width: "40vw",
    pointerEvents: "auto",
    backgroundColor: "#f5faff",
    boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.8)",
    zIndex: "20",
  },
  subMenuButton: {
    width: "100%",
    fontFamily: "Big Shoulders Text",
    fontSize: "14px",
    fontWeight: 400,
    textDecoration: "none",
    textTransform: "none",
    color: "#0c345a",
    "&:hover": {
      backgroundColor: "#0c345a",
      color: "#cce3f8",
    },
  },
}));

export default function Header({ setHeaderFilterBy }) {
  const classes = useStyles();
  const [products, setProducts] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openPoppover = Boolean(anchorEl);
  const location = useLocation();

  // const [isScrolling, setIsScrolling] = useState(false);

  // useEffect(() => {
  //   document.addEventListener("scroll", () => {
  //     if (window.scrollY > 100) {
  //       setIsScrolling(true);
  //     } else {
  //       setIsScrolling(false);
  //     }
  //   });
  // });

  useEffect(() => {
    client
      .fetch(
        `*[_type == "productService"]{
            reviewTitle,
            productTitle,
            brand,
            slug,
            "productCategories": productCategories[] -> title,
            "concernCategories": concernCategories[] -> title,
            mainImage {
              asset -> {
                _id,  
                url
              }
          },
        }`
      )
      .then((data) => {
        setProducts(data);
      })
      .catch(console.error);
  }, []);

  const getUniqueProductCategories = () => {
    const uniqueProductCategories = new Set();
    if (products) {
      products.map((product) => {
        if (product.productCategories) {
          product.productCategories.map((productCategory) =>
            uniqueProductCategories.add(productCategory)
          );
        }
        return null;
      });
    }
    const labelArray = Array.from(uniqueProductCategories);
    return labelArray.map((label) => (
      <div key={label}>
        <Link to="/products" style={{ textDecoration: "none" }}>
          <Button
            size={"small"}
            className={classes.subMenuButton}
            onClick={() => handlePopoverClick(label)}
          >
            {label}
          </Button>
        </Link>
      </div>
    ));
  };

  const getUniqueConcernCategories = () => {
    const uniqueConcernCategories = new Set();
    if (products) {
      products.map((product) => {
        if (product.concernCategories) {
          product.concernCategories.map((concernCategory) =>
            uniqueConcernCategories.add(concernCategory)
          );
        }
        return null;
      });
    }
    const labelArray = Array.from(uniqueConcernCategories);
    return labelArray.map((label) => (
      <div key={label}>
        <Link to="/products" style={{ textDecoration: "none" }}>
          <Button
            size={"small"}
            className={classes.subMenuButton}
            onClick={() => handlePopoverClick(label)}
          >
            {label}
          </Button>
        </Link>
      </div>
    ));
  };

  const getLinkPath = (menuText) => {
    switch (menuText) {
      case "Home":
        return "";
      case "About":
        return "about";
      case "Contact":
        return "contact";
      case "Reviews":
        return "reviews";
      case "Products":
        return "products";
      case "Expert Advice":
        return "expert-advice";
      case "Categories":
        return "categories";
      default:
        return "";
    }
  };

  const getNavButtonTitle = () => {
    switch (location.pathname) {
      case "/":
        return "Home";
      case "/about":
        return "About";
      case "/contact":
        return "Contact";
      case "/reviews":
        return "Reviews";
      case "/products":
        return "Products";
      case "/expert-advice":
        return "Expert Advice";
      case "/categories":
        return "Categories";
      default:
        return "Home";
    }
  };

  const list = () => (
    <Box
      className={classes.navBox}
      role="presentation"
      onClick={() => setOpen(false)}
      onKeyDown={() => setOpen(false)}
    >
      <List>
        {["Home", "Products", "Expert Advice", "About", "Contact"].map(
          (text, index) => (
            <div key={text}>
              <Link
                to={`/${getLinkPath(text)}`}
                style={{ textDecoration: "none" }}
              >
                <ListItem className={classes.listItem}>{text}</ListItem>
              </Link>
            </div>
          )
        )}
      </List>
    </Box>
  );

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handlePopoverClick = (label) => {
    setHeaderFilterBy(label);
    setAnchorEl(null);
  };

  return (
    <Row className={"g-0"}>
      <Col xs={12} className={classes.headerRoot}>
        <Row className={"g-0 w-100"}>
          <Col xl={1}></Col>
          <Col xl={10}>
            <Row className={"g-0 w-100 align-items-center"}>
              <Col lg={3} md={10}>
                <Hidden mdDown>
                  <div className="logo">
                    <Link to="/">
                      <img
                        src={brandLogo}
                        alt="logo"
                        className={classes.brandLogo}
                      />
                    </Link>
                  </div>
                </Hidden>
                <Hidden mdUp>
                  <div className="logo">
                    <Link to="/">
                      <img
                        src={brandLogo}
                        alt="logo"
                        className={classes.brandLogo}
                      />
                    </Link>
                  </div>
                </Hidden>
              </Col>
              <Col lg={6}>
                <Hidden only={["md", "sm", "xs"]}>
                  <div className={classes.navButtons}>
                    <Link to="/" style={{ textDecoration: "none" }}>
                      <Button
                        size="small"
                        className={clsx(
                          classes.buttonStyle,
                          classes.leftButton
                        )}
                      >
                        Home
                      </Button>
                    </Link>
                    <Divider
                      style={{
                        height: "auto",
                        width: "2px",
                        background: "#d8b0af",
                        opacity: 1,
                      }}
                      orientation="vertical"
                      flexItem
                    />
                    <div
                      onMouseLeave={handlePopoverClose}
                      onMouseEnter={handlePopoverOpen}
                    >
                      <Link to="/products" style={{ textDecoration: "none" }}>
                        <Button
                          size="small"
                          className={clsx(
                            classes.buttonStyle,
                            classes.midButton
                          )}
                          onClick={() => handlePopoverClose()}
                        >
                          Products
                        </Button>
                      </Link>
                      <Popper
                        id={"new popper"}
                        className={classes.popper}
                        open={openPoppover}
                        anchorEl={anchorEl}
                        modifiers={[
                          {
                            name: "offset",
                            options: {
                              offset: [55, 0],
                            },
                          },
                        ]}
                      >
                        <div
                          style={{
                            backgroundColor: "#f5faff",
                            height: "100%",
                            padding: "15px",
                            fontFamily: "Big Shoulders Text",
                          }}
                        >
                          <Row style={{ paddingBottom: "15px" }}>
                            <Col
                              lg={3}
                              style={{ borderRight: "2px solid #d8b0af" }}
                            >
                              <div
                                style={{
                                  paddingBottom: "20px",
                                  fontSize: "18px",
                                }}
                              >
                                Skin Care Step
                              </div>
                              {getUniqueProductCategories()}
                            </Col>
                            <Col
                              lg={3}
                              style={{ borderRight: "2px solid #d8b0af" }}
                            >
                              <div
                                style={{
                                  paddingBottom: "20px",
                                  fontSize: "18px",
                                }}
                              >
                                Concern Categories
                              </div>
                              {getUniqueConcernCategories()}
                            </Col>
                            <Col
                              lg={3}
                              style={{ borderRight: "2px solid #d8b0af" }}
                            >
                              <div
                                style={{
                                  paddingBottom: "20px",
                                  fontSize: "18px",
                                }}
                              >
                                Ingredient Profiles
                              </div>
                            </Col>
                            <Col lg={3}>
                              <div
                                style={{
                                  paddingBottom: "20px",
                                  fontSize: "18px",
                                }}
                              >
                                Skin Profiles
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Popper>
                    </div>
                    <Divider
                      style={{
                        height: "auto",
                        width: "2px",
                        background: "#d8b0af",
                        opacity: 1,
                      }}
                      orientation="vertical"
                      flexItem
                    />
                    <Link
                      to="/expert-advice"
                      style={{ textDecoration: "none" }}
                    >
                      <Button
                        size="small"
                        className={clsx(classes.buttonStyle, classes.midButton)}
                      >
                        Expert Advice
                      </Button>
                    </Link>
                    <Divider
                      style={{
                        height: "auto",
                        width: "2px",
                        background: "#d8b0af",
                        opacity: 1,
                      }}
                      orientation="vertical"
                      flexItem
                    />
                    <Link to="/about" style={{ textDecoration: "none" }}>
                      <Button
                        size="small"
                        className={clsx(classes.buttonStyle, classes.midButton)}
                      >
                        About
                      </Button>
                    </Link>
                    <Divider
                      style={{
                        height: "auto",
                        width: "2px",
                        background: "#d8b0af",
                        opacity: 1,
                      }}
                      orientation="vertical"
                      flexItem
                    />
                    <Link to="/contact" style={{ textDecoration: "none" }}>
                      <Button
                        size="small"
                        className={clsx(
                          classes.buttonStyle,
                          classes.rightButton
                        )}
                      >
                        Contact
                      </Button>
                    </Link>
                  </div>
                </Hidden>
              </Col>
              <Col lg={3} md={2}>
                <Hidden mdDown>
                  <Search products={products} />
                </Hidden>

                <Hidden mdUp>
                  <div className={classes.smallNavDrawer}>
                    <Button
                      variant="contained"
                      className={classes.smallNavDrawerButton}
                      size="small"
                      onClick={handleDrawerOpen}
                      endIcon={<MenuIcon />}
                    >
                      {getNavButtonTitle().toUpperCase()}
                    </Button>

                    <Drawer
                      anchor={"top"}
                      open={open}
                      onClose={() => setOpen(false)}
                    >
                      {list()}
                    </Drawer>
                  </div>
                </Hidden>
              </Col>
            </Row>
          </Col>
          <Col xl={1}></Col>
        </Row>
      </Col>
    </Row>
  );
}
