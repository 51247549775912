import React from "react";
import BlockContent from "@sanity/block-content-to-react";
import urlBuilder from "@sanity/image-url";
import Hidden from "@mui/material/Hidden";

const urlFor = (source) =>
  urlBuilder({ projectId: "gx31uli0", dataset: "production" }).image(source);

const serializer = {
  types: {
    block: (props) => {
      switch (props.node.style) {
        case "h1":
          return <h1>{props.children}</h1>;
        case "h2":
          return (
            <h2 style={{ fontSize: "22px", fontWeight: "bold" }}>
              {props.children}
            </h2>
          );
        case "h3":
          return (
            <h3 style={{ fontSize: "20px", fontWeight: "bold" }}>
              {props.children}
            </h3>
          );
        case "h4":
          return <h3 style={{ fontSize: "16px" }}>{props.children}</h3>;
        // case "blockquote":
        //   return <blockquote className="">{props.children}</blockquote>;
        // case "normal":
        //   if (props.listItem) return <strong>{props.children}</strong>;
        //   else
        //     return (
        //       <p className="is-family-secondary is-size-5">{props.children}</p>
        //     );
        default:
          return <p className="is-family-secondary">{props.children}</p>;
      }
    },
    image: (props) => (
      <div style={{ textAlign: "center" }}>
        <Hidden mdUp>
          <img
            src={urlFor(props.node.asset)
              .auto("format")
              .fit("crop")
              .height(150)
              .width(200)}
            alt={props.node.alt ? props.node.alt : "default alt"}
            style={{
              borderRadius: "10px",
              height: "150px",
              width: "200px",
              margin: "10px",
            }}
          />
        </Hidden>
        <Hidden mdDown>
          <img
            src={urlFor(props.node.asset)
              .auto("format")
              .fit("crop")
              .height(250)
              .width(333)}
            alt={props.node.alt ? props.node.alt : "default alt"}
            style={{
              borderRadius: "10px",
              height: "250px",
              width: "333px",
              margin: "10px",
            }}
          />
        </Hidden>
      </div>
    ),
  },
};

const TransformBlockContent = ({ blocks }) => (
  <BlockContent
    blocks={blocks}
    projectId="gx31uli0"
    dataset="production"
    serializers={serializer}
  />
);

export default TransformBlockContent;
